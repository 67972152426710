import {
 ArrowRightEndOnRectangleIcon,
 CheckIcon,
 ShoppingBagIcon,
 ShoppingCartIcon,
} from '@heroicons/react/20/solid'
import { Link } from '@remix-run/react'
import React from 'react'

const steps = [
 {
  name: 'Sign Up',
  description: "Register to browse today's grocery donations",
  status: 'complete',
  icon: ArrowRightEndOnRectangleIcon,
 },
 {
  name: 'Shop',
  description: 'Place your order online',
  additionalInfo: 'Handling and service fees may apply',
  status: 'complete',
  icon: ShoppingCartIcon,
 },
 {
  name: 'Pickup',
  description: 'Head over to grab your donations',
  link: 'https://www.congress.gov/bill/117th-congress/house-bill/6251',
  status: 'complete',
  icon: ShoppingBagIcon,
 },
]

function classNames(...classes) {
 return classes.filter(Boolean).join(' ')
}

export default function Steps() {
 return (
  <div className="align-center mx-auto max-w-lg px-6 pb-5 pt-5">
   <div className="relative isolate sm:rounded-3xl">
    <p className="text-1xl mt-2 pb-5 text-center text-lg font-bold tracking-tight text-warmTerracota-700">
     Pilot Program
    </p>
    <nav aria-label="Progress">
     <ol role="list" className="overflow-hidden">
      {steps.map((step, stepIdx) => (
       <li
        key={step.name}
        className={classNames(
         stepIdx !== steps.length - 1 ? 'pb-10' : '',
         'relative'
        )}
       >
        {step.status === 'complete' ? (
         <>
          {stepIdx !== steps.length - 1 ? (
           <div
            className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-warmTerracota-700"
            aria-hidden="true"
           />
          ) : null}
          <div className="group relative flex items-start">
           <span className="flex h-9 items-center">
            <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-warmTerracota-700">
             <step.icon className="h-5 w-5 text-white" aria-hidden="true" />
            </span>
           </span>
           <span className="ml-4 flex min-w-0 flex-col">
            <span className="text-base font-medium">{step.name}</span>
            <span className="text-base text-gray-500">{step.description}</span>
            {step.additionalInfo ? (
             <span className="text-xs text-gray-500">
              {step.additionalInfo}
              {/* <Link
                              to={step.link}
                              target='_blank'
                              prefetch='viewport'
                              className="text-warmTerracota-700"> Learn more
                            </Link> */}
             </span>
            ) : null}
           </span>
          </div>
         </>
        ) : (
         ''
        )}
       </li>
      ))}
     </ol>
    </nav>
   </div>
  </div>
 )
}
